export default [
  {
    header: "Content",
    permission: "adminmaster",
  },
  {
    title: "Post",
    route: "post",
    icon: "CreditCardIcon",
    children: [
      {
        title: "Post",
        route: "post",
        permission: "adminmaster",
      },
      {
        title: "Post Category",
        route: "post-category",
        permission: "adminmaster",
      },
      {
        title: "Post Thread/Question",
        route: "thread-question",
        permission: "adminmaster",
      },
    ],
  },
  {
    title: "E-Learning",
    icon: "BookIcon",
    permission: "adminmaster",
    children: [
      {
        title: "E-Learning",
        route: "e-learning",
        permission: "adminmaster",
      },
      {
        title: "E-Learning Category",
        route: "e-learning-category",
        permission: "adminmaster",
      },
    ],
  },
  {
    title: "Sister Talk",
    icon: "MessageCircleIcon",
    children: [
      {
        title: "Forum",
        children: [
          {
            title: "List",
            route: "sister-talk-forum",
            permission: "adminmaster",
          },
          {
            title: "Category",
            route: "sister-talk-category",
            permission: "adminmaster",
          },
        ],
      },
      {
        title: "Mentorship",
        children: [
          {
            title: "Mentor",
            route: "mentor",
            permission: "adminmaster",
          },
          {
            title: "Mentor Session",
            route: "mentorship-booked-schedules",
            permission: "adminmaster",
          },
        ],
      },
    ],
  },
  {
    title: "Users",
    route: "users",
    icon: "UserIcon",
    permission: "adminmaster",
  },
  {
    title: "Webinar",
    route: "webinar",
    icon: "StarIcon",
    permission: "adminmaster",
  },
  {
    title: "Competition",
    route: "competition",
    icon: "InstagramIcon",
    permission: "adminmaster",
  },
  {
    title: "Banner",
    route: "banner",
    icon: "ImageIcon",
    permission: "adminmaster",
  },
  // {
  // 	title: 'Journey',
  // 	route: 'journey',
  // 	icon: 'ImageIcon'
  // },
  {
    title: "Bank",
    route: "bank",
    icon: "CreditCardIcon",
    permission: "adminmaster",
  },
  {
    title: "Toko Sister",
    route: "toko-sister",
    icon: "ShoppingCartIcon",
    permission: "adminmaster",
  },
  {
    title: "Redeem History",
    route: "redeem-history",
    icon: "FileTextIcon",
    permission: "adminmaster",
  },
  {
    title: "Logo Partner",
    route: "logo-partner",
    icon: "ImageIcon",
    permission: "adminmaster",
  },
  {
    title: "Female Future Leader Timeline",
    route: "female-future-leader-timeline",
    icon: "ClockIcon",
    permission: "adminmaster",
  },
  {
    title: "Female Future Leader Timeline Activity",
    route: "female-future-leader-timeline-activity",
    icon: "ActivityIcon",
    permission: "adminmaster",
  },
  {
    title: "Female Future Leader Participant",
    route: "female-future-leader-participant",
    icon: "UsersIcon",
    permission: "adminmaster",
  },
  {
    title: "Community",
    route: "community",
    icon: "Link2Icon",
    permission: "adminmaster",
  },
  {
    title: "Page Setting",
    icon: "ClockIcon",
    permission: "adminmaster",
    children: [
      {
        title: "InternSis",
        route: "intern-sis",
        permission: "adminmaster",
      },
      {
        title: "Freebies Rewards",
        route: "freebies-rewards",
        permission: "adminmaster",
      },
      {
        title: "Female Future Leader",
        children: [
          {
            title: "Female Future Leader Information",
            route: "female-future-leader",
            permission: "adminmaster",
          },
          // {
          //   title: "Female Future Leader Continue Register",
          //   route: "female-future-leader-continue-register",
          //   permission: "adminmaster",
          // },
        ],
      },
    ]
  },
  {
    title: "App Setting",
    icon: "SettingsIcon",
    children: [
      {
        title: "On/Off Feature",
        route: "feature",
        permission: "adminmaster",
      },
      {
        title: "Setting",
        route: "setting",
        permission: "adminmaster",
      },
      {
        title: "Thematic UI Setting",
        route: "thematic-ui-setting",
        permission: "adminmaster",
      },
      {
        title: "Version",
        route: "version",
        permission: "adminmaster",
      },
    ],
  },
  {
    title: "Third-party Log",
    icon: "GitCommitIcon",
    permission: "adminmaster",
    children: [
      {
        title: "Comarch",
        route: "comarch",
        permission: "adminmaster",
      },
      {
        title: "XL-Digital Rewards",
        route: "freebies-rewards-log",
        permission: "adminmaster",
      },
    ],
  },
];
