<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div
      class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"
    >
      <dark-Toggler class="d-none d-lg-block" />
    </div>
    <div
      :class="
        userPermission.includes('adminmaster')
          ? 'd-flex align-items-center'
          : ''
      "
    >
      <button
        class="btn btn-primary"
        @click="changeRole()"
        v-if="userPermission.includes('adminmaster')"
      >
        {{ userRole === "adminmaster" ? "Mentorship Mode" : "Admin Mode" }}
      </button>
      <b-navbar-nav class="nav align-items-center ml-auto">
        <b-nav-item-dropdown
          right
          toggle-class="d-flex align-items-center dropdown-user-link"
          class="dropdown-user"
        >
          <template #button-content>
            <div class="d-sm-flex d-none user-nav">
              <p class="user-name font-weight-bolder mb-0">
                Hi, {{ userData.name }}
              </p>
            </div>
            <b-avatar
              :src="userData.avatar"
              size="40"
              variant="light-primary"
              badge
              class="badge-minimal"
              badge-variant="success"
            />
          </template>

          <b-dropdown-item
            link-class="d-flex align-items-center"
            to="/profile-mentor"
          >
            <feather-icon size="16" icon="UserIcon" class="mr-50" />
            <span>Profile</span>
          </b-dropdown-item>
          <b-dropdown-item
            link-class="d-flex align-items-center"
            @click="logout"
          >
            <feather-icon size="16" icon="LogOutIcon" class="mr-50" />
            <span>Logout</span>
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </div>
  </div>
</template>

<script>
import {
  BLink,
  BNavbarNav,
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import { checkPermission } from "@/auth/utils";
import DarkToggler from "@core/layouts/components/app-navbar/components/DarkToggler.vue";

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },

  setup() {
    return {
      checkPermission,
    };
  },

  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      userRole: localStorage.getItem("userRole") || "adminmaster",
      userPermission: JSON.parse(localStorage.getItem("userPermission")),
    };
  },

  methods: {
    changeRole() {
      if (this.userRole === "adminmaster") {
        this.userRole = "mentorship";
        localStorage.setItem("userRole", this.userRole);
        this.$router.push({ name: "mentorship-session" });
        setTimeout(() => {
          window.location.reload();
        }, 100);
      } else {
        this.userRole = "adminmaster";
        localStorage.setItem("userRole", this.userRole);
        this.$router.push({ name: "home" });
        setTimeout(() => {
          window.location.reload();
        }, 100);
      }
    },

    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);

      // Remove userData from localStorage
      localStorage.removeItem("userData");
      localStorage.removeItem("userRole");
      localStorage.removeItem("userPermission");

      // Redirect to login page
      this.$router.push({ name: "login" });
    },
  },
};
</script>
