import dashboard from "./dashboard";
import mentorship from "./mentorship";
import content from "./content";

// Array of sections
const activeMode = localStorage.getItem("userRole") || "adminmaster";
let menus = [...dashboard, ...content];
if (activeMode == "mentorship") {
  // Array of sections
  menus = [...mentorship];
}
export default menus;
