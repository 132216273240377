export default [
  {
    header: "Mentorship",
    permission: "mentorship-mentor",
  },
  {
    title: "Session",
    icon: "BookIcon",
    route: "mentorship-session",
    permission: "mentorship-mentor",
  },
  {
    title: "Topic",
    icon: "MessageCircleIcon",
    route: "mentorship-topic",
    permission: "mentorship-mentor",
  },
];
